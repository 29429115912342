.container {
    display: flex;
    justify-content: center;
    align-items: middle;
    text-align: center;
    min-height: 100vh;
    margin-top: 100px;
}
.box{
    max-width: 400px;
}
h1{
    margin-bottom: 30px;
}